<template>
  <div
    id="diagram-village"
    class="part-component part-diagram flex layout-align-center-center"
  >
    <div class="diagram-wrap relative">
      <div
        v-for="(item, i) in data.items"
        :key="i"
        class="diagram-item absolute crosshair"
        :data-item="i + 1"
        :style="{ width: pos[i].width, left: pos[i].left, top: pos[i].top }"
        @mouseover="itemHover(i)"
        @mouseleave="itemHover(-1)"
        @click="itemClick(i)"
        :class="{
          active: itemStatus.active.includes(i),
          inactive: itemStatus.inactive.includes(i),
        }"
      >
        <div
          class="diagram-image parallax-item parallax-relative"
          :data-depth="oddEvenAlternate(i, 2.8)"
        >
          <img
            :src="`https://media.londolozi.com/africa/diagrams/village/${
              i + 1
            }.png`"
            :alt="item.name"
          />
        </div>
        <div
          class="diagram-caption absolute parallax-item"
          :data-depth="oddEvenAlternate(i, 2.1)"
        >
          <div class="diagram-name h4">{{ item.name }}</div>
          <div class="diagram-sub sub">{{ item.sub }}</div>
        </div>
        <div class="diagram-icons no-select">
          <i
            v-for="cat in item.cat"
            :key="cat"
            :class="`ai-${cat}`"
            :style="{ color: iconColors[cat] }"
          ></i>
        </div>
      </div>
      <div class="diagram-svg parallax-item" data-depth="1.8">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 909 840"
          style="enable-background: new 0 0 909 840"
          xml:space="preserve"
        >
          <defs>
            <clipPath id="clip-path">
              <path
                id="path"
                d="M194.6,95c-0.6,0-1.2,0.2-1.6,0.7l-40.9,39.7L50,156.4c-0.9,0.2-1.6,0.8-1.9,1.7l-18,60.4
		c-0.1,0.4-0.1,0.9,0,1.3l21.2,78.4c0.2,0.6,0.5,1.1,1.1,1.5l56.7,33L85.4,420c-0.2,0.7,0,1.5,0.5,2.1l66.2,71.2l-39.5,196.3l0,0
		c-0.1,0.3,0,0.6,0.1,0.9c0.3,0.5,0.8,0.8,1.3,0.8c0.2,0,0.4,0,0.6-0.2L341,578.8c0.1-0.1,0.2-0.2,0.1-0.3c0-0.1-0.1-0.1-0.2-0.1
		c0,0-0.1,0-0.1,0L116.1,687.3l27.7-133.4c7.6,1.2,15.3,2,23,2.7c8.3,0.7,16.6,1.4,24.9,1.6c2.5,0.1,4.9,0.1,7.4,0.1
		c2.2,0,4.3,0,6.5,0c3.7,0,7.4,0,11.1,0.2c8.3,0.3,16.6,1.1,24.8,1.9c8.3,0.9,16.5,1.8,24.8,2.3c8.3,0.6,16.6,1.3,24.9,1.7
		c11.2,0.5,22.5,0.8,33.8,0.8c5.4,0,10.7-0.1,16.1-0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3c-16.5-2.5-33-3.9-49.6-5.1
		c-8.3-0.5-16.6-0.8-24.9-1.1c-8.3-0.4-16.6-0.5-24.9-0.5c-8.3-0.1-16.6-0.2-24.9-0.9c-8.3-0.6-16.5-1.8-24.8-2.6
		c-8.3-0.7-16.6-1.1-24.9-1.3c-3.9-0.1-7.8-0.1-11.7-0.1c-3.8,0-7.6,0.1-11.4,0.2l12.4-60c0.1-0.4,0-0.8-0.1-1.2
		c12.8-7.2,25.6-14.4,38.3-21.8l20-11.5l19.9-11.6c8-4.6,15.9-9.3,23.9-14c7.5,9.8,15.1,19.5,22.7,29.2c8,10.3,16.2,20.4,24.2,30.6
		l12.2,15.3l12.3,15.2c8.2,10.1,16.4,20.2,24.7,30.2c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.1-0.3
		c-7.6-10.6-15.3-21.1-23.1-31.5L320.1,506l-11.7-15.6c-7.9-10.4-15.6-20.8-23.6-31.1c-7.3-9.6-14.7-19.2-22.2-28.8
		c17-10.1,34-20.3,51-30.5c26.2-16,52.5-31.9,78.5-48.2l39.1-24.3c6.4-3.9,12.7-7.8,19.1-11.6c-0.4,7.7-0.3,15.3-0.1,22.9
		c0.2,8,0.5,15.9,1.3,23.8c0.8,7.9,2,15.8,2.6,23.8c0.6,7.9,0.8,15.9,0.9,23.9c0,8,0.1,15.9,0.5,23.9c0.3,8,0.6,15.9,1.1,23.9
		c1.1,15.1,2.5,30.1,4.7,45.1c-0.2,0.1-0.4,0.1-0.6,0.3c-7.9,4.6-15.9,9.1-23.7,13.7l-23.6,13.9c-7.9,4.6-15.7,9.4-23.5,14.1
		c-7.8,4.7-15.6,9.5-23.4,14.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c8.1-4.2,16.2-8.4,24.3-12.7
		c8.1-4.3,16.1-8.6,24.2-13l24.1-13.2c7.9-4.3,15.7-8.7,23.6-13.1l18.9-3.1l19.3-3.3c12.9-2.3,25.8-4.4,38.6-6.8l0.1,0
		c0.1,0,0.2,0,0.3-0.1c20-7.7,39.7-16,59.8-23.6l15-5.7c5-1.8,10-3.7,15.1-5.5c10.1-3.6,20.2-7,30.3-10.6c4.4-1.5,8.8-3,13.1-4.6
		c-10.5,11.4-20.8,23-31.1,34.6c-5.7,6.4-11.3,12.7-16.9,19.2l-16.8,19.3c-11.2,12.9-22.2,25.9-33,39
		c-10.9,13.1-21.6,26.4-32.6,39.4l0,0c0,0-0.1,0.1-0.1,0.2c-2.7,5.8-5.6,11.5-8.4,17.3l-8.6,17.2c-2.9,5.7-5.7,11.5-8.6,17.1
		l-7.9,15.5l-152.8-88.9l0,0c-0.2-0.1-0.4-0.2-0.6-0.2c0,0,0,0,0,0c-0.6,0-1.1,0.5-1.1,1.2l3,159c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0
		c0.1,0,0.2-0.1,0.2-0.2l-1.2-157l151,89.2l27.2,100.1c0.2,0.8,1,1.4,1.8,1.4c0,0,0,0,0,0l143.6,0.2l143.6-0.1h0
		c1.1,0,1.9-0.9,1.9-2L825.9,656v0c0-0.4-0.2-0.8-0.4-1.1l-39-40.8l-3-105.4l-2.9-106c0-0.1,0-0.2-0.1-0.3l-34-67.8l-33.8-67.7
		l9.8-157.7c0,0,0,0,0,0c0-0.6-0.5-1.1-1.1-1.1l-263.4-6.6L194.6,95C194.7,95,194.6,95,194.6,95L194.6,95z M319.9,262.1l-5.7-11.2
		c-2.5-5-5.1-10-7.7-15c-2.5-5-5.2-9.9-7.8-14.9l-7.8-14.9l0,0c-0.1-0.2-0.2-0.3-0.4-0.4c-3.9-1.7-7.7-3.6-11.5-5.4
		c-3.8-1.8-7.6-3.6-11.4-5.6c-7.5-3.6-14.8-7.5-22.2-11.4l-4.8-9.4c-1.7-3.2-3.3-6.5-5-9.7c-0.2-0.4-0.6-0.7-1-0.9l-0.1,0
		c-6.7-2.4-13.4-4.8-20.1-7c-6.7-2.3-13.5-4.5-20.2-6.6c-6.8-2.2-13.5-4.3-20.3-6.4c-3.4-1-6.8-2-10.2-3c-2.5-0.7-5-1.4-7.5-2.1
		l39.4-38.5L458,105l108.6,2.1c-4.1,7.5-8,15.1-12,22.7l-12.6,24.5c-1.7,3.3-3.4,6.7-5.1,10c-5.7,1.5-11.5,3.1-17.2,4.6
		c-3.2,0.9-6.4,1.8-9.6,2.7l-9.5,2.7c-0.1,0-0.3,0.1-0.4,0.2l-0.1,0c-7,5-14,10.2-20.9,15.3c-6.9,5.2-13.9,10.3-20.7,15.5
		c-6.9,5.2-13.7,10.5-20.5,15.9l-20,15.9c-6.6-0.3-13.2-0.8-19.7-1.2l-10.1-0.8l-10.1-0.9c-6.7-0.6-13.4-1.1-20.1-1.8l-20.1-2
		c0,0-0.1,0-0.1,0c-0.5,0-1,0.3-1.2,0.7l0,0.1c-3.1,5.8-6.3,11.5-9.4,17.3L319.9,262.1L319.9,262.1z M471.3,302.5
		c8.1-16.1,16.5-32.1,24.7-48.1c8.5-16.3,16.7-32.7,24.7-49.2c8-16.5,16.1-33,23.9-49.6l11.7-24.9c3.6-7.9,7.3-15.7,10.8-23.6l153,3
		l-9.2,156.7c-10,0.6-19.8,1.7-29.7,2.9c-10.1,1.2-20.2,2.6-30.2,4.4c-10,1.8-20,4.1-30,5.7c-10,1.7-20.2,2.9-30.3,4
		c-10.1,1.1-20.3,2.2-30.3,3.6c-10.1,1.3-20.2,2.6-30.3,4.2C510.6,294.6,490.9,298.1,471.3,302.5L471.3,302.5z M112.9,329.4
		l-57.2-33.3L35,219.3l17.4-58.3l101.4-21c0.4-0.1,0.8-0.3,1.2-0.6l0.6-0.5c2.5,1,5.1,2,7.6,3c3.3,1.3,6.6,2.6,9.9,3.9
		c6.6,2.5,13.3,5,20,7.4c6.7,2.4,13.4,4.8,20.1,7.1c6.5,2.3,13,4.5,19.5,6.6c1.7,2.9,3.5,5.8,5.2,8.7l5.6,9.4l0,0.1c0,0,0,0,0,0
		c-5.5,5.6-10.9,11.3-16.3,17l-16.9,18.1c-11.3,12-22.4,24.3-33.5,36.5c-11.1,12.2-22.1,24.6-32.8,37.1
		C133.5,305.6,123.3,317.6,112.9,329.4L112.9,329.4z M418.3,239.5c0.2,0,0.4-0.1,0.6-0.2l0,0l21.1-15.1c7-5,14-10.2,20.9-15.4
		c6.9-5.2,13.7-10.5,20.6-15.7c6.8-5.3,13.5-10.5,20.2-15.9l9.1-3.1c3.1-1.1,6.3-2.1,9.4-3.2c5.4-1.9,10.8-3.8,16.1-5.7
		c-6.5,12.7-12.9,25.5-19.2,38.2c-8.2,16.4-16.2,32.9-24,49.5c-7.8,16.5-15.5,33.1-23.5,49.5c-13,8-26.2,15.7-39.3,23.5l-21,12.2
		c-6.9-6.1-13.9-12.2-20.9-18.2c-7.3-6.3-14.7-12.5-22.1-18.6c-7.4-6.2-14.9-12.2-22.4-18.3l-11.3-9l-10.1-7.9l7.9-15.8
		c2.8-5.6,5.5-11.2,8.2-16.8l19.2,1.1c6.7,0.4,13.4,0.9,20.2,1.4l10.1,0.7l10.1,0.8c6.7,0.6,13.4,1.2,20.1,2
		C418.2,239.5,418.3,239.5,418.3,239.5L418.3,239.5z M259.1,427l-35.7-23.5c-12.3-8.1-24.7-16.1-37-24.1l-37.2-23.9
		c-11.7-7.7-23.4-15.3-35-23.2l0,0c0.2-0.6,0.1-1.2-0.2-1.8c10.7-11.6,21.5-23.1,32.3-34.6c11.3-12,22.5-24.2,33.4-36.6
		c11-12.3,22-24.7,32.7-37.2l16.2-18.8c5.1-6,10.2-11.9,15.2-18c0,0,0,0,0.1,0c7.7,3.5,15.4,6.9,23,10.6c3.8,1.8,7.6,3.7,11.4,5.6
		c3.7,1.9,7.3,3.7,11,5.6l6.9,15c2.3,5.1,4.6,10.2,7,15.3c2.4,5.1,4.7,10.2,7.2,15.2l6.4,13.3l-35.6,18.8
		c-12.5,6.7-24.9,13.5-37.3,20.3c-12.3,6.9-24.7,13.8-37,20.8l-18.4,10.6c-6.1,3.6-12.2,7.1-18.3,10.8c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c6.4-3.1,12.7-6.3,19.1-9.4l18.9-9.6c12.6-6.4,25.1-13,37.7-19.5c12.5-6.6,25-13.2,37.4-20
		l35.8-19.7l10.2,8.3l11.3,9c7.6,5.9,15.2,11.9,22.8,17.8c7.6,5.9,15.3,11.7,23.1,17.5c7.3,5.5,14.7,10.9,22.2,16.3L390.4,349
		c-26.6,15.4-53,31.1-79.4,46.7C293.7,406.1,276.4,416.5,259.1,427L259.1,427z M692.8,432c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.1-0.2,0-0.3c-12.2-12.7-25-24.6-38-36.3c-6.5-5.8-13.3-11.4-20-17.1c-6.7-5.7-13.5-11.2-20.4-16.6
		c-6.9-5.5-13.6-11-20.1-16.9c-6.5-5.9-12.6-12.2-19-18.2c-6.4-6-13.1-11.6-19.9-17.2c-5.8-4.7-11.7-9.4-17.7-13.9
		c7.8-1.2,15.5-2.5,23.2-3.8c10.1-1.6,20.1-3.5,30.1-5.4c10-1.9,20-3.7,30.1-5c10.1-1.4,20.3-2.1,30.4-3.3
		c10.1-1.2,20.2-2.9,30.2-4.6c9.8-1.7,19.6-3.6,29.3-5.9l34,67.8l33.7,67.6c-4.8,1.5-9.6,3.2-14.5,4.8c-5.1,1.7-10.1,3.4-15.2,5.2
		c-10.1,3.5-20.2,7-30.3,10.7c-20.1,7.3-40.2,14.7-60,22.9c-9.9,4-19.8,8.1-29.8,11.9c-5,2-10,3.8-15,5.8l-15.1,5.5
		c-20,7.4-40.3,14.1-60.3,21.4c-12.9,1.9-25.7,4-38.6,5.9l-19.4,3l-18.6,3.1c0.5-15.1,0.1-30.2-0.6-45.2c-0.4-7.9-1.1-15.9-1.7-23.8
		c-0.6-7.9-1.4-15.9-2.3-23.8c-0.9-7.9-1.6-15.8-1.9-23.8c-0.3-8,0-15.9-0.2-23.9c-0.2-8-0.8-15.9-1.5-23.8
		c-0.7-7.7-1.6-15.4-2.9-23.1c6.5-4,13.1-7.9,19.7-11.8c0.1-0.1,0.2-0.2,0.3-0.3l0,0c0-0.1,0.1-0.1,0.1-0.2c20.1-1.5,40.1-4,60-6.7
		c1.8-0.3,3.7-0.5,5.5-0.8c5.4,5.7,11.1,11.1,16.7,16.4c6.4,6,12.9,11.8,19.7,17.4c6.8,5.5,13.9,10.7,20.5,16.4
		c6.7,5.6,13.1,11.6,19.4,17.7c6.3,6.1,12.6,12.1,19.2,18c6.5,5.9,12.9,11.8,19.6,17.5c13.3,11.4,26.9,22.5,41,32.9
		C692.7,432,692.8,432,692.8,432L692.8,432z M155.9,491.4C155.9,491.4,155.9,491.4,155.9,491.4L155.9,491.4L90.1,420l23.7-86.3
		c11.5,7.6,22.9,15.4,34.4,23.2l36.3,25.2c12.1,8.4,24.3,16.7,36.5,25l33.5,22.7c-7.4,4.5-14.8,9.1-22.2,13.7l-19.6,12.1l-19.5,12.2
		C180.7,475.6,168.3,483.5,155.9,491.4L155.9,491.4z M511.7,663.7l7.1-15c2.7-5.8,5.5-11.5,8.3-17.3l8.4-17.3
		c2.8-5.7,5.6-11.4,8.5-17.1c11.2-12.8,22.6-25.4,33.9-38.2c11.4-12.7,22.5-25.6,33.5-38.6l16.5-19.5c5.5-6.5,10.9-13.1,16.4-19.7
		c10.2-12.3,20.3-24.6,30.2-37c15.3-5.5,30.5-11.3,45.7-17.1c10-3.8,19.9-7.8,29.9-11.8c5-2,9.9-4,14.9-6.1
		c4.6-1.9,9.3-3.9,13.9-5.8l2.4,105.4l2.3,106c-22.6,3.4-45.2,7.1-67.8,10.9c-23,4-46,7.9-69,12.1c-23,4.2-45.9,8.6-68.8,13
		C555.8,654.8,533.8,659.2,511.7,663.7L511.7,663.7z M538.5,764.3l-26.9-97.8c22.4-3.7,44.8-7.5,67.2-11.5c23-4.1,46-8.1,69-12.4
		c23-4.3,45.9-8.8,68.8-13.4c22.4-4.5,44.9-9.1,67.3-14c0.1,0.2,0.2,0.3,0.3,0.4l0,0l38.6,41l-0.3,107.6l-141.7-0.1L538.5,764.3
		L538.5,764.3z"
              />
            </clipPath>
          </defs>
          <g style="clip-path: url(#clip-path)">
            <path
              id="village-path"
              d="M194.6,95c-0.6,0-1.2,0.2-1.6,0.7l-40.9,39.7L50,156.4c-0.9,0.2-1.6,0.8-1.9,1.7l-18,60.4
		c-0.1,0.4-0.1,0.9,0,1.3l21.2,78.4c0.2,0.6,0.5,1.1,1.1,1.5l56.7,33L85.4,420c-0.2,0.7,0,1.5,0.5,2.1l66.2,71.2l-39.5,196.3l0,0
		c-0.1,0.3,0,0.6,0.1,0.9c0.3,0.5,0.8,0.8,1.3,0.8c0.2,0,0.4,0,0.6-0.2L341,578.8c0.1-0.1,0.2-0.2,0.1-0.3c0-0.1-0.1-0.1-0.2-0.1
		c0,0-0.1,0-0.1,0L116.1,687.3l27.7-133.4c7.6,1.2,15.3,2,23,2.7c8.3,0.7,16.6,1.4,24.9,1.6c2.5,0.1,4.9,0.1,7.4,0.1
		c2.2,0,4.3,0,6.5,0c3.7,0,7.4,0,11.1,0.2c8.3,0.3,16.6,1.1,24.8,1.9c8.3,0.9,16.5,1.8,24.8,2.3c8.3,0.6,16.6,1.3,24.9,1.7
		c11.2,0.5,22.5,0.8,33.8,0.8c5.4,0,10.7-0.1,16.1-0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3c-16.5-2.5-33-3.9-49.6-5.1
		c-8.3-0.5-16.6-0.8-24.9-1.1c-8.3-0.4-16.6-0.5-24.9-0.5c-8.3-0.1-16.6-0.2-24.9-0.9c-8.3-0.6-16.5-1.8-24.8-2.6
		c-8.3-0.7-16.6-1.1-24.9-1.3c-3.9-0.1-7.8-0.1-11.7-0.1c-3.8,0-7.6,0.1-11.4,0.2l12.4-60c0.1-0.4,0-0.8-0.1-1.2
		c12.8-7.2,25.6-14.4,38.3-21.8l20-11.5l19.9-11.6c8-4.6,15.9-9.3,23.9-14c7.5,9.8,15.1,19.5,22.7,29.2c8,10.3,16.2,20.4,24.2,30.6
		l12.2,15.3l12.3,15.2c8.2,10.1,16.4,20.2,24.7,30.2c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.1-0.3
		c-7.6-10.6-15.3-21.1-23.1-31.5L320.1,506l-11.7-15.6c-7.9-10.4-15.6-20.8-23.6-31.1c-7.3-9.6-14.7-19.2-22.2-28.8
		c17-10.1,34-20.3,51-30.5c26.2-16,52.5-31.9,78.5-48.2l39.1-24.3c6.4-3.9,12.7-7.8,19.1-11.6c-0.4,7.7-0.3,15.3-0.1,22.9
		c0.2,8,0.5,15.9,1.3,23.8c0.8,7.9,2,15.8,2.6,23.8c0.6,7.9,0.8,15.9,0.9,23.9c0,8,0.1,15.9,0.5,23.9c0.3,8,0.6,15.9,1.1,23.9
		c1.1,15.1,2.5,30.1,4.7,45.1c-0.2,0.1-0.4,0.1-0.6,0.3c-7.9,4.6-15.9,9.1-23.7,13.7l-23.6,13.9c-7.9,4.6-15.7,9.4-23.5,14.1
		c-7.8,4.7-15.6,9.5-23.4,14.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c8.1-4.2,16.2-8.4,24.3-12.7
		c8.1-4.3,16.1-8.6,24.2-13l24.1-13.2c7.9-4.3,15.7-8.7,23.6-13.1l18.9-3.1l19.3-3.3c12.9-2.3,25.8-4.4,38.6-6.8l0.1,0
		c0.1,0,0.2,0,0.3-0.1c20-7.7,39.7-16,59.8-23.6l15-5.7c5-1.8,10-3.7,15.1-5.5c10.1-3.6,20.2-7,30.3-10.6c4.4-1.5,8.8-3,13.1-4.6
		c-10.5,11.4-20.8,23-31.1,34.6c-5.7,6.4-11.3,12.7-16.9,19.2l-16.8,19.3c-11.2,12.9-22.2,25.9-33,39
		c-10.9,13.1-21.6,26.4-32.6,39.4l0,0c0,0-0.1,0.1-0.1,0.2c-2.7,5.8-5.6,11.5-8.4,17.3l-8.6,17.2c-2.9,5.7-5.7,11.5-8.6,17.1
		l-7.9,15.5l-152.8-88.9l0,0c-0.2-0.1-0.4-0.2-0.6-0.2c0,0,0,0,0,0c-0.6,0-1.1,0.5-1.1,1.2l3,159c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0
		c0.1,0,0.2-0.1,0.2-0.2l-1.2-157l151,89.2l27.2,100.1c0.2,0.8,1,1.4,1.8,1.4c0,0,0,0,0,0l143.6,0.2l143.6-0.1h0
		c1.1,0,1.9-0.9,1.9-2L825.9,656v0c0-0.4-0.2-0.8-0.4-1.1l-39-40.8l-3-105.4l-2.9-106c0-0.1,0-0.2-0.1-0.3l-34-67.8l-33.8-67.7
		l9.8-157.7c0,0,0,0,0,0c0-0.6-0.5-1.1-1.1-1.1l-263.4-6.6L194.6,95C194.7,95,194.6,95,194.6,95L194.6,95z M319.9,262.1l-5.7-11.2
		c-2.5-5-5.1-10-7.7-15c-2.5-5-5.2-9.9-7.8-14.9l-7.8-14.9l0,0c-0.1-0.2-0.2-0.3-0.4-0.4c-3.9-1.7-7.7-3.6-11.5-5.4
		c-3.8-1.8-7.6-3.6-11.4-5.6c-7.5-3.6-14.8-7.5-22.2-11.4l-4.8-9.4c-1.7-3.2-3.3-6.5-5-9.7c-0.2-0.4-0.6-0.7-1-0.9l-0.1,0
		c-6.7-2.4-13.4-4.8-20.1-7c-6.7-2.3-13.5-4.5-20.2-6.6c-6.8-2.2-13.5-4.3-20.3-6.4c-3.4-1-6.8-2-10.2-3c-2.5-0.7-5-1.4-7.5-2.1
		l39.4-38.5L458,105l108.6,2.1c-4.1,7.5-8,15.1-12,22.7l-12.6,24.5c-1.7,3.3-3.4,6.7-5.1,10c-5.7,1.5-11.5,3.1-17.2,4.6
		c-3.2,0.9-6.4,1.8-9.6,2.7l-9.5,2.7c-0.1,0-0.3,0.1-0.4,0.2l-0.1,0c-7,5-14,10.2-20.9,15.3c-6.9,5.2-13.9,10.3-20.7,15.5
		c-6.9,5.2-13.7,10.5-20.5,15.9l-20,15.9c-6.6-0.3-13.2-0.8-19.7-1.2l-10.1-0.8l-10.1-0.9c-6.7-0.6-13.4-1.1-20.1-1.8l-20.1-2
		c0,0-0.1,0-0.1,0c-0.5,0-1,0.3-1.2,0.7l0,0.1c-3.1,5.8-6.3,11.5-9.4,17.3L319.9,262.1L319.9,262.1z M471.3,302.5
		c8.1-16.1,16.5-32.1,24.7-48.1c8.5-16.3,16.7-32.7,24.7-49.2c8-16.5,16.1-33,23.9-49.6l11.7-24.9c3.6-7.9,7.3-15.7,10.8-23.6l153,3
		l-9.2,156.7c-10,0.6-19.8,1.7-29.7,2.9c-10.1,1.2-20.2,2.6-30.2,4.4c-10,1.8-20,4.1-30,5.7c-10,1.7-20.2,2.9-30.3,4
		c-10.1,1.1-20.3,2.2-30.3,3.6c-10.1,1.3-20.2,2.6-30.3,4.2C510.6,294.6,490.9,298.1,471.3,302.5L471.3,302.5z M112.9,329.4
		l-57.2-33.3L35,219.3l17.4-58.3l101.4-21c0.4-0.1,0.8-0.3,1.2-0.6l0.6-0.5c2.5,1,5.1,2,7.6,3c3.3,1.3,6.6,2.6,9.9,3.9
		c6.6,2.5,13.3,5,20,7.4c6.7,2.4,13.4,4.8,20.1,7.1c6.5,2.3,13,4.5,19.5,6.6c1.7,2.9,3.5,5.8,5.2,8.7l5.6,9.4l0,0.1c0,0,0,0,0,0
		c-5.5,5.6-10.9,11.3-16.3,17l-16.9,18.1c-11.3,12-22.4,24.3-33.5,36.5c-11.1,12.2-22.1,24.6-32.8,37.1
		C133.5,305.6,123.3,317.6,112.9,329.4L112.9,329.4z M418.3,239.5c0.2,0,0.4-0.1,0.6-0.2l0,0l21.1-15.1c7-5,14-10.2,20.9-15.4
		c6.9-5.2,13.7-10.5,20.6-15.7c6.8-5.3,13.5-10.5,20.2-15.9l9.1-3.1c3.1-1.1,6.3-2.1,9.4-3.2c5.4-1.9,10.8-3.8,16.1-5.7
		c-6.5,12.7-12.9,25.5-19.2,38.2c-8.2,16.4-16.2,32.9-24,49.5c-7.8,16.5-15.5,33.1-23.5,49.5c-13,8-26.2,15.7-39.3,23.5l-21,12.2
		c-6.9-6.1-13.9-12.2-20.9-18.2c-7.3-6.3-14.7-12.5-22.1-18.6c-7.4-6.2-14.9-12.2-22.4-18.3l-11.3-9l-10.1-7.9l7.9-15.8
		c2.8-5.6,5.5-11.2,8.2-16.8l19.2,1.1c6.7,0.4,13.4,0.9,20.2,1.4l10.1,0.7l10.1,0.8c6.7,0.6,13.4,1.2,20.1,2
		C418.2,239.5,418.3,239.5,418.3,239.5L418.3,239.5z M259.1,427l-35.7-23.5c-12.3-8.1-24.7-16.1-37-24.1l-37.2-23.9
		c-11.7-7.7-23.4-15.3-35-23.2l0,0c0.2-0.6,0.1-1.2-0.2-1.8c10.7-11.6,21.5-23.1,32.3-34.6c11.3-12,22.5-24.2,33.4-36.6
		c11-12.3,22-24.7,32.7-37.2l16.2-18.8c5.1-6,10.2-11.9,15.2-18c0,0,0,0,0.1,0c7.7,3.5,15.4,6.9,23,10.6c3.8,1.8,7.6,3.7,11.4,5.6
		c3.7,1.9,7.3,3.7,11,5.6l6.9,15c2.3,5.1,4.6,10.2,7,15.3c2.4,5.1,4.7,10.2,7.2,15.2l6.4,13.3l-35.6,18.8
		c-12.5,6.7-24.9,13.5-37.3,20.3c-12.3,6.9-24.7,13.8-37,20.8l-18.4,10.6c-6.1,3.6-12.2,7.1-18.3,10.8c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c6.4-3.1,12.7-6.3,19.1-9.4l18.9-9.6c12.6-6.4,25.1-13,37.7-19.5c12.5-6.6,25-13.2,37.4-20
		l35.8-19.7l10.2,8.3l11.3,9c7.6,5.9,15.2,11.9,22.8,17.8c7.6,5.9,15.3,11.7,23.1,17.5c7.3,5.5,14.7,10.9,22.2,16.3L390.4,349
		c-26.6,15.4-53,31.1-79.4,46.7C293.7,406.1,276.4,416.5,259.1,427L259.1,427z M692.8,432c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.1-0.2,0-0.3c-12.2-12.7-25-24.6-38-36.3c-6.5-5.8-13.3-11.4-20-17.1c-6.7-5.7-13.5-11.2-20.4-16.6
		c-6.9-5.5-13.6-11-20.1-16.9c-6.5-5.9-12.6-12.2-19-18.2c-6.4-6-13.1-11.6-19.9-17.2c-5.8-4.7-11.7-9.4-17.7-13.9
		c7.8-1.2,15.5-2.5,23.2-3.8c10.1-1.6,20.1-3.5,30.1-5.4c10-1.9,20-3.7,30.1-5c10.1-1.4,20.3-2.1,30.4-3.3
		c10.1-1.2,20.2-2.9,30.2-4.6c9.8-1.7,19.6-3.6,29.3-5.9l34,67.8l33.7,67.6c-4.8,1.5-9.6,3.2-14.5,4.8c-5.1,1.7-10.1,3.4-15.2,5.2
		c-10.1,3.5-20.2,7-30.3,10.7c-20.1,7.3-40.2,14.7-60,22.9c-9.9,4-19.8,8.1-29.8,11.9c-5,2-10,3.8-15,5.8l-15.1,5.5
		c-20,7.4-40.3,14.1-60.3,21.4c-12.9,1.9-25.7,4-38.6,5.9l-19.4,3l-18.6,3.1c0.5-15.1,0.1-30.2-0.6-45.2c-0.4-7.9-1.1-15.9-1.7-23.8
		c-0.6-7.9-1.4-15.9-2.3-23.8c-0.9-7.9-1.6-15.8-1.9-23.8c-0.3-8,0-15.9-0.2-23.9c-0.2-8-0.8-15.9-1.5-23.8
		c-0.7-7.7-1.6-15.4-2.9-23.1c6.5-4,13.1-7.9,19.7-11.8c0.1-0.1,0.2-0.2,0.3-0.3l0,0c0-0.1,0.1-0.1,0.1-0.2c20.1-1.5,40.1-4,60-6.7
		c1.8-0.3,3.7-0.5,5.5-0.8c5.4,5.7,11.1,11.1,16.7,16.4c6.4,6,12.9,11.8,19.7,17.4c6.8,5.5,13.9,10.7,20.5,16.4
		c6.7,5.6,13.1,11.6,19.4,17.7c6.3,6.1,12.6,12.1,19.2,18c6.5,5.9,12.9,11.8,19.6,17.5c13.3,11.4,26.9,22.5,41,32.9
		C692.7,432,692.8,432,692.8,432L692.8,432z M155.9,491.4C155.9,491.4,155.9,491.4,155.9,491.4L155.9,491.4L90.1,420l23.7-86.3
		c11.5,7.6,22.9,15.4,34.4,23.2l36.3,25.2c12.1,8.4,24.3,16.7,36.5,25l33.5,22.7c-7.4,4.5-14.8,9.1-22.2,13.7l-19.6,12.1l-19.5,12.2
		C180.7,475.6,168.3,483.5,155.9,491.4L155.9,491.4z M511.7,663.7l7.1-15c2.7-5.8,5.5-11.5,8.3-17.3l8.4-17.3
		c2.8-5.7,5.6-11.4,8.5-17.1c11.2-12.8,22.6-25.4,33.9-38.2c11.4-12.7,22.5-25.6,33.5-38.6l16.5-19.5c5.5-6.5,10.9-13.1,16.4-19.7
		c10.2-12.3,20.3-24.6,30.2-37c15.3-5.5,30.5-11.3,45.7-17.1c10-3.8,19.9-7.8,29.9-11.8c5-2,9.9-4,14.9-6.1
		c4.6-1.9,9.3-3.9,13.9-5.8l2.4,105.4l2.3,106c-22.6,3.4-45.2,7.1-67.8,10.9c-23,4-46,7.9-69,12.1c-23,4.2-45.9,8.6-68.8,13
		C555.8,654.8,533.8,659.2,511.7,663.7L511.7,663.7z M538.5,764.3l-26.9-97.8c22.4-3.7,44.8-7.5,67.2-11.5c23-4.1,46-8.1,69-12.4
		c23-4.3,45.9-8.8,68.8-13.4c22.4-4.5,44.9-9.1,67.3-14c0.1,0.2,0.2,0.3,0.3,0.4l0,0l38.6,41l-0.3,107.6l-141.7-0.1L538.5,764.3
		L538.5,764.3z"
            />
          </g>
        </svg>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" class="defs-only hidden">
      <filter
        id="ubuntu"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="linearRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
          in="SourceGraphic"
          result="colormatrix"
        />
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues="0.36 1" />
          <feFuncG type="table" tableValues="0.5 1" />
          <feFuncB type="table" tableValues="0.62 1" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="color"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>
      <filter
        id="education"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="linearRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
          in="SourceGraphic"
          result="colormatrix"
        />
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues="0.93 1" />
          <feFuncG type="table" tableValues="0.12 1" />
          <feFuncB type="table" tableValues="0.46 1" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="color"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>
      <filter
        id="environment"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="linearRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
          in="SourceGraphic"
          result="colormatrix"
        />
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues="0.39 1" />
          <feFuncG type="table" tableValues="0.55 1" />
          <feFuncB type="table" tableValues="0.26 1" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="color"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>
      <filter
        id="health"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="linearRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
          in="SourceGraphic"
          result="colormatrix"
        />
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues="0.82 1" />
          <feFuncG type="table" tableValues="0.14 1" />
          <feFuncB type="table" tableValues="0.16 1" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="color"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>
      <filter
        id="villagelife"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="linearRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
          in="SourceGraphic"
          result="colormatrix"
        />
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues="0 1" />
          <feFuncG type="table" tableValues="0.65 1" />
          <feFuncB type="table" tableValues="0.79 1" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="color"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>

      <filter
        id="building"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="linearRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
          in="SourceGraphic"
          result="colormatrix"
        />
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues="0.14 1" />
          <feFuncG type="table" tableValues="0.25 1" />
          <feFuncB type="table" tableValues="0.56 1" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="color"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>
    </svg>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "DiagramVillage",
  mixins: [partComponent],
  props: ["data", "itemStatus", "iconColors"],
  computed: {
    pos() {
      const width = this.data.width;
      const height = this.data.height;
      return this.data.items.map((item) => {
        return {
          width: ((item.width / width) * 100).toFixed(2) + "%",
          left: ((item.x / width) * 100).toFixed(2) + "%",
          top: ((item.y / height) * 100).toFixed(2) + "%",
        };
      });
    },
  },
  data() {
    return {};
  },
  methods: {
    itemHover(val) {
      if (device.type != "desktop") {
        return;
      }
      this.$emit("item-hover", val);
    },
    itemClick(val) {
      if (device.type == "desktop") {
        return;
      }
      this.$emit("item-click", val);
    },
    oddEvenAlternate(i, n) {
      //even
      let factor = i % 2 == 0 ? 1 : 1.3;

      return n * factor;
    },
    createTimeline() {
      const villagePath = "#village-path";

      const tl = new TimelineMax({
        paused: true,
      });

      tl.set(
        ".diagram-wrap",
        {
          autoAlpha: 1,
        },
        0
      )
        .staggerFrom(
          ".diagram-image",
          0.6,
          {
            y: "+=50",
            autoAlpha: 0,
            ease: Power2.easeOut,
            scale: 0.9,
          },
          0.06,
          0
        )
        .staggerFrom(
          ".diagram-caption",
          0.6,
          {
            y: "+=50",
            autoAlpha: 0,
            ease: Power2.easeOut,
            scale: 0.9,
          },
          0.06,
          ".6"
        )
        .staggerFrom(
          ".diagram-icons",
          0.6,
          {
            autoAlpha: 0,
            ease: Power2.easeIn,
          },
          0.06,
          "1"
        )
        .from(
          villagePath,
          2.3,
          {
            drawSVG: "50% 50%",
            autoAlpha: 0,
            ease: Power1.easeOut,
          },
          ".4"
        );

      this.timeline = tl;

      const tlReverse = new TimelineMax({
        paused: true,
      });

      tlReverse
        .staggerTo(
          ".diagram-image",
          0.6,
          {
            autoAlpha: 0,
            ease: Power2.easeInOut,
          },
          0.07,
          0
        )
        .staggerTo(
          ".diagram-caption",
          0.6,
          {
            autoAlpha: 0,
            ease: Power2.easeInOut,
          },
          0.07,
          ".3"
        )
        .staggerTo(
          ".diagram-icons",
          0.6,
          {
            autoAlpha: 0,
            ease: Power2.easeIn,
          },
          0.06,
          "0.3"
        )
        .to(
          villagePath,
          2.3,
          {
            drawSVG: "50% 50%",
            autoAlpha: 0,
            ease: Power1.easeOut,
          },
          ".3"
        );

      this.timelineReverse = tlReverse;
    },
  },
};
</script>

<style scoped>
#village-path {
  stroke: #e5e5e5;
  fill: none;
  stroke-width: 5;
}
.part-diagram {
  width: 100%;
  max-width: 910px;
  height: 100%;
  margin-right: 5%;
}
.diagram-wrap {
  width: 100%;
  height: 1px;
  padding-top: 92.3%;
  opacity: 0;
}
.diagram-item {
  transform-origin: center center;
  font-size: 1.063rem;
  z-index: 1;
  transition: 0.3s 0.2s ease-out;
}
.diagram-image {
  z-index: 1;
}
.diagram-item img {
  max-width: 100%;
  display: block;
  transition: 0.3s 0.2s filter ease-out;
  filter: grayscale(0) drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.3));
}
.diagram-caption {
  display: block;
  width: 10.588em;
  pointer-events: none;
  z-index: -1;
}
.diagram-name.h4 {
  font-size: 1em;
  letter-spacing: 0.02em;
  padding-left: 0.5em;
}
.diagram-sub {
  padding-left: 2em;
  letter-spacing: 0.01em;
  font-size: 0.706em;
}

.diagram-svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.diagram-svg svg {
  fill: none;
}

.diagram-icons {
  font-size: 0.941em;
  z-index: -1;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  transform: translate(100%, 0);
}
.diagram-icons i {
  font-size: 2em;
  height: 0.9em;
  transition: 0.3s 0.2s ease-out;
}
.diagram-item:not(.active) .diagram-icons i {
  color: var(--grey) !important;
}
.diagram-item.active .diagram-icons i {
  font-size: 2.1em;
}
.diagram-icons i.ai-education {
  font-size: 1.8em;
  margin-bottom: 0.1em;
  margin-left: 0.1em;
}
.diagram-item.active .diagram-icons i.ai-education {
  font-size: 1.9em;
}
.ai-health {
  margin-top: -0.06em;
}

.diagram-item.active {
  transform: scale(1.1);
  z-index: 4;
}
.diagram-item.active img {
  filter: grayscale(0) drop-shadow(-1px 5px 5px rgba(0, 0, 0, 0.2));
}

.diagram-item.inactive {
  transform: scale(0.9);
  opacity: 0.3;
  transition-timing-function: ease-in;
}
.diagram-item.inactive img {
  filter: grayscale(100) drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.1));
  transition-timing-function: ease-in;
}

.filter-ubuntu .diagram-item:not(.inactive) .diagram-image {
  filter: url(#ubuntu);
}
.filter-education .diagram-item:not(.inactive) .diagram-image {
  filter: url(#education);
}
.filter-environment .diagram-item:not(.inactive) .diagram-image {
  filter: url(#environment);
}
.filter-health .diagram-item:not(.inactive) .diagram-image {
  filter: url(#health);
}
.filter-village-life .diagram-item:not(.inactive) .diagram-image {
  filter: url(#villagelife);
}
.filter-building .diagram-item:not(.inactive) .diagram-image {
  filter: url(#building);
}

.diagram-item[data-item="1"] .diagram-icons {
  right: -0.6em;
  top: 1.7em;
}
.diagram-item[data-item="2"] .diagram-icons {
  right: -0.3em;
  top: 1em;
}
.diagram-item[data-item="3"] .diagram-icons {
  right: 0.6em;
  top: 4.3em;
}
.diagram-item[data-item="4"] .diagram-icons {
  right: 0em;
  top: 4.5em;
}
.diagram-item[data-item="5"] .diagram-icons {
  right: -0.4em;
  top: 1em;
}
.diagram-item[data-item="6"] .diagram-icons {
  right: -0.2em;
  top: 3.9em;
}
.diagram-item[data-item="7"] .diagram-icons {
  right: 1.9em;
  top: 0.5em;
}
.diagram-item[data-item="8"] .diagram-icons {
  right: -0.4em;
  top: 2em;
}
.diagram-item[data-item="9"] .diagram-icons {
  right: -0.6em;
  top: 1em;
}
.diagram-item[data-item="10"] .diagram-icons {
  right: 0.3em;
  top: 4.2em;
}
.diagram-item[data-item="11"] .diagram-icons {
  right: -0.6em;
  top: 2em;
}
.diagram-item[data-item="12"] .diagram-icons {
  right: -0.2em;
  top: 0.9em;
}
.diagram-item[data-item="13"] .diagram-icons {
  right: 2em;
  top: 1em;
}
.diagram-item[data-item="14"] .diagram-icons {
  right: 1.9em;
  top: 0;
}
.diagram-item[data-item="15"] .diagram-icons {
  right: -0.8em;
  top: 2em;
}
.diagram-item[data-item="16"] .diagram-icons {
  right: -0.8em;
  top: 0.5em;
}
.diagram-item[data-item="17"] .diagram-icons {
  right: -0.6em;
  top: 2em;
}

.diagram-item[data-item="1"] .diagram-caption {
  top: -38% !important;
  left: 39% !important;
}

.diagram-item[data-item="2"] .diagram-caption {
  top: 54% !important;
  left: 72% !important;
}
.diagram-item[data-item="3"] .diagram-caption {
  top: 110% !important;
  left: 45% !important;
}
.diagram-item[data-item="4"] .diagram-caption {
  top: 111% !important;
  left: 34% !important;
  width: 8em;
}
.diagram-item[data-item="5"] .diagram-caption {
  top: 105% !important;
  left: -3.7% !important;
}
.diagram-item[data-item="6"] .diagram-caption {
  top: 109% !important;
  left: -13% !important;
}
.diagram-item[data-item="7"] .diagram-caption {
  top: 94% !important;
  left: -20% !important;
}
.diagram-item[data-item="8"] .diagram-caption {
  top: 104% !important;
  left: 42% !important;
}
.diagram-item[data-item="9"] .diagram-caption {
  top: 62% !important;
  left: 96% !important;
}
.diagram-item[data-item="10"] .diagram-caption {
  top: 111% !important;
  left: 23% !important;
  width: 8em;
}
.diagram-item[data-item="11"] .diagram-caption {
  top: 116% !important;
  left: 24% !important;
}
.diagram-item[data-item="12"] .diagram-caption {
  top: 95% !important;
  left: -41% !important;
}
.diagram-item[data-item="13"] .diagram-caption {
  top: 80% !important;
  left: -22% !important;
}
.diagram-item[data-item="14"] .diagram-caption {
  top: 50% !important;
  left: -39% !important;
}
.diagram-item[data-item="15"] .diagram-caption {
  top: 114% !important;
  left: -16% !important;
}
.diagram-item[data-item="16"] .diagram-caption {
  top: 89% !important;
  left: 51% !important;
}
.diagram-item[data-item="17"] .diagram-caption {
  top: 114% !important;
  left: 8% !important;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1200px) {
  .diagram-item {
    font-size: 14.5px;
  }
  .diagram-sub {
    font-size: 10.5px;
  }
  .diagram-icons {
    font-size: 0.7em;
  }
}
@media screen and (max-width: 640px) {
  .diagram-item {
    font-size: 14px;
  }
  .diagram-sub {
    font-size: 10.5px;
  }
  .diagram-icons {
    font-size: 0.65em;
  }
}
@media screen and (max-width: 639px) {
  .diagram-sub {
    padding-left: 2em;
  }
  .diagram-name.h4 {
    font-size: 0.9em;
    padding-left: 0.2em;
  }
}
</style>
