import { render, staticRenderFns } from "./DiagramVillage.vue?vue&type=template&id=07221ff0&scoped=true&"
import script from "./DiagramVillage.vue?vue&type=script&lang=js&"
export * from "./DiagramVillage.vue?vue&type=script&lang=js&"
import style0 from "./DiagramVillage.vue?vue&type=style&index=0&id=07221ff0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07221ff0",
  null
  
)

export default component.exports